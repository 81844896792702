import React, { Component } from "react"

class Mac extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div class="mac-window">
          <div class="mac-titlebar">
            <span class="close mac-titlebar-button "></span>
            <span class="min mac-titlebar-button "></span>
            <span class="max mac-titlebar-button "></span>
            <div class="title">{this.props.title}</div>
          </div>
          <div class="console-text">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default Mac
