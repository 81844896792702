import React, { Component } from "react"
import SEO from "../components/seo"
import { AwesomeButton, AwesomeButtonSocial } from "react-awesome-button"
import "react-awesome-button/dist/themes/theme-rickiest.css"

import Typed from "react-typed"
import posed from "react-pose"

import Mac from "../components/mac"
import Menu from "../components/menu"

const ScrollDown = posed.div({
  off: {
    y: -300,
    scale: 0,
  },
  on: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
})

const data = [
  {
    commands: "cat about.txt",
    output: `Hi, I am Frederic Ronaldi. Technology and computer have been in my life since I
            was a kid, making me enjoy building things that live on the internet. I develop exceptional apps and website that provide intuitive, pixel-perfect user interface with efficient and modern backends.
            I entered Bandung Institute of Techology in 2019 and currently a freshman pursuing in Informatics/Computer Science.`,
  },
  {
    commands: "ls -la ./skills",
    output: <div className="skills">
              <span>Languages:</span>
              <AwesomeButton type="link">Java</AwesomeButton>
              <AwesomeButton type="secondary">Python</AwesomeButton>
              <AwesomeButton type="primary">HTML</AwesomeButton>
              <AwesomeButton type="link">CSS</AwesomeButton>
              <AwesomeButton type="secondary">Javascript</AwesomeButton>
              <AwesomeButton type="primary">C and C++</AwesomeButton>
              <AwesomeButton type="link">Haskell</AwesomeButton>
              <span>Additionals:</span>
              <AwesomeButton type="link">SCSS & SASS</AwesomeButton>
              <AwesomeButton type="secondary">Typescript</AwesomeButton>
              <AwesomeButton type="primary">Git</AwesomeButton>
              <AwesomeButton type="link">Docker</AwesomeButton>
              <AwesomeButton type="secondary">GraphQL</AwesomeButton>
              <AwesomeButton type="primary">AWS</AwesomeButton>
              <AwesomeButton type="link">Google Cloud</AwesomeButton>
              <span>Frameworks and Libraries:</span>
              <AwesomeButton type="link">jQuery</AwesomeButton>
              <AwesomeButton type="secondary">Bootstrap</AwesomeButton>
              <AwesomeButton type="primary">React & React Native</AwesomeButton>
              <AwesomeButton type="link">ExpressJS</AwesomeButton>
              <AwesomeButton type="secondary">GatsbyJS</AwesomeButton>
              <AwesomeButton type="primary">ElectronJS</AwesomeButton>
              <AwesomeButton type="link">NextJS</AwesomeButton>
              <AwesomeButton type="secondary">Socket.io</AwesomeButton>
              <span>Databases:</span>
              <AwesomeButton type="link">MongoDB</AwesomeButton>
              <AwesomeButton type="secondary">MySQL</AwesomeButton>
              <AwesomeButton type="primary">PostgreSQL</AwesomeButton>
              <AwesomeButton type="link">Firebase</AwesomeButton>
              <AwesomeButton type="link">DynamoDB</AwesomeButton>
              <span>Tools:</span>
              <AwesomeButton type="link">Adobe Photoshop</AwesomeButton>
              <AwesomeButton type="secondary">Adobe Illustrator</AwesomeButton>
              <AwesomeButton type="primary">Adobe Premierre</AwesomeButton>
              <AwesomeButton type="link">Adobe After Effects</AwesomeButton>
              <AwesomeButton type="secondary">Figma</AwesomeButton>
            </div>,
  },
  {
    commands: "ls -la ./socials",
    output: <div className="socials-btn">
              <AwesomeButtonSocial
                type="instagram"
                href="https://instagram.com/frederon8"
                target="_blank"
              >
                Instagram
              </AwesomeButtonSocial>
              <AwesomeButtonSocial
                type="github"
                href="https://github.com/frederon/"
                target="_blank"
              >
                Github
              </AwesomeButtonSocial>
              <AwesomeButtonSocial
                type="linkedin"
                href="https://www.linkedin.com/in/frederic-ronaldi/"
                target="_blank"
              >
                LinkedIn
              </AwesomeButtonSocial>
              <AwesomeButton
                type="github"
                href="https://steamcommunity.com/id/frederon/"
                target="_blank"
              >
                Steam
              </AwesomeButton>
            </div>,
  },
]
class AboutPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showAbout: false,
      show0: false,
      show1: false,
      show2: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showAbout: true,
      })
    }, 200)
  }

  render() {
    return (
      <>
        <SEO title="About" />
        <ScrollDown pose={this.state.showAbout ? "on" : "off"}>
          <Mac title="Terminal" className="overflow-container about">
            {data.map((value, index) => {
              const style = {
                visibility: this.state["show" + index] ? "visible" : "hidden",
                opacity: this.state["show" + index] ? "1" : "0",
                transition: "visibility 0s, opacity 0.5s linear"
              }
              return <><Typed strings={[
                // '' + value.commands + '^1000\n `' + value.output + '`',
                '$ ' + value.commands
              ]}
                typeSpeed={20}
                startDelay={index * 1200}
                showCursor={false}
                onComplete={() => this.setState({ ["show"+index]: true })}
              ></Typed><span style={style}>{value.output}</span></>
            })}
          </Mac>
        </ScrollDown>
        <Menu active="about" />
      </>
    )
  }
}

export default AboutPage
